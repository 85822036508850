import { Outlet } from "react-router-dom";

const ErrorLayout = () => {
  return (
    <div className="min-h-screen flex flex-col w-full">
      <main className="h-full transition-all bg-lightPrimary relative flex flex-grow">
        <div className="flex-1">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default ErrorLayout;
