import { Button } from "flowbite-react";
import { Link } from "react-router-dom";

export default function Error403() {
  return (
    <div className="flex justify-center h-screen w-screen items-center">
      <div className="flex flex-col items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900">Uh-oh</h1>
          <p className="text-lg mt-3 text-gray-500">
            Se pare că ai încercat să accesezi o pagină neautorizată.
          </p>
          <Link to="/">
            <Button color="brand" className="text-base md:text-lg lg:text-xl font-bold mt-4 mx-auto">Către prima pagină</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
