import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "components/spinner";
import { User } from "api/models/user";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "store";
import useAdminAuth from "auth/useAdminAuth";

export type ProtectedRouteProps = {
  children: JSX.Element | any[];
  condition?: (user: User) => boolean;
  authenticatedOnly?: boolean;
  adminOnly?: boolean;
  redirectPath?: string;
};

const ProtectedRoute = ({
  children,
  condition = () => true,
  adminOnly = false,
  authenticatedOnly = true,
  redirectPath = "/"
}: Readonly<ProtectedRouteProps>) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { isAdmin } = useAdminAuth();
  const storedUser = useAppSelector((state) => state.currentUser);

  if (authenticatedOnly && !isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });

    return <Spinner />
  };

  if ((adminOnly && !isAdmin()) ||
    !condition(storedUser)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ?? <Outlet />;
};

export default ProtectedRoute;
