export default function ErrorOffline() {
  return (
    <div className="flex justify-center h-screen w-screen items-center">
      <div className="flex flex-col items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900">Uh-oh</h1>
          <p className="text-lg mt-3 text-gray-500">
            Din păcate avem probleme cu conexiunea la serverele noastre. Te rugăm să încerci din nou mai târziu.
          </p>
        </div>
      </div>
    </div>
  );
}
