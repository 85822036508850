import { useAuth0 } from "@auth0/auth0-react";
import useLotsHub from "api/hubs/lotsHub";
import { LotSummary } from "api/models/lotSummary";
import LotSummaryCard from "components/card/LotSummaryCard";
import { useState } from "react";
import { useAppSelector } from "store";

const LotsListView = ({ lotSummaries }: { lotSummaries: LotSummary[] }) => {
  const user = useAppSelector((state) => state.currentUser);
  const { isAuthenticated } = useAuth0();
  const [lots, setLots] = useState(lotSummaries);
  useLotsHub(lots.map(lot => lot.id),
    (notification) => {
      setLots(prevData => {
        return prevData.map(prevLot => {
          if (prevLot.id !== notification.lotId) {
            return prevLot;
          }

          return {
            ...prevLot,
            priceDetails: {
              ...prevLot.priceDetails,
              currentPrice: notification.amount
            },
            latestBid: {
              ...prevLot.latestBid,
              isCurrentUserBid: isAuthenticated && notification.userId === user.id
            },
            bidsCount: prevLot.bidsCount + 1
          };
        });
      });
    },
    (notification) => {
      setLots(prevData => {
        return prevData.map(prevLot => {
          if (prevLot.id !== notification.lotId) {
            return prevLot;
          }

          return {
            ...prevLot,
            endsAtUtc: notification.endsAtUtc
          };
        });
      });
    },
    (notification) => {
      setLots(prevData => {
        return prevData.map(prevLot => {
          if (prevLot.id !== notification.lotId) {
            return prevLot;
          }

          return {
            ...prevLot,
            status: notification.status
          };
        });
      });
    },
  );

  return <>
    {
      lots.map((lot) => (
        <LotSummaryCard key={lot.id} lot={lot} />
      ))
    }
  </>;
};

export default LotsListView;
